<template>
    <div class="description">
        <div class="fx" style="border-bottom:1px solid #ccc;padding-bottom:10px;">
            <div>会员单位申请 - 入会说明</div>
        </div>
        <div class="content">
            <div class="t-center">{{description.name}}</div>
            <div v-html="description.rank_rights"></div>
            <div class="btn" @click="$router.push('/memberUnitIndex')">在线申请</div>
        </div>
    </div>
</template>

<script>
import {explain} from '@/api/commit'
export default {
    data() {
        return {
            description:null
        };
    },
    created() {
        this.getExplain()
    },
    mounted() {

    },
    methods: {
        getExplain(){
            explain(2).then(res=>{
                if(res.code==200){
                    this.description = res.data
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    .description{
        .content{
            padding:0px 20px;
            .t-center{
                margin: 30px 0px;
            }
            .btn{
                width: 340px;
                height: 48px;
                line-height: 48px;
                color: #fff;
                margin:40px auto;
                text-align: center;
                background: #CC0000;
                border-radius: 24px;
                cursor: pointer;
            }
        }
    }
</style>
